<template>
  <div class="classify-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="搜索">
              <el-input v-model="searchForm.search" placeholder="搜索关键词"></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select v-model="searchForm.node_type" placeholder="选择节点类型" style="width: 200px;">
                <el-option
                  v-for="type in classifyTypes"
                  :key="type.value"
                  :label="type.key"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序字段">
              <el-select v-model="searchForm.sortBy" placeholder="选择排序字段" style="width: 200px;">
                <el-option label="名称" value="name"></el-option>
                <el-option label="类型" value="type"></el-option>
                <el-option label="层级" value="level"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否降序">
              <el-switch v-model="searchForm.sortDesc"></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchNodes">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="primary" @click="openCreateDialog()">新增根节点</el-button>
        </div>
      </div>
    </el-card>

    <el-card class="tree-card">
      <el-tree
        ref="treeRef"
        v-if="treeData && treeData.length > 0"
        :data="treeData"
        :props="defaultProps"
        node-key="id"
        :expand-on-click-node="false"
        lazy
        :load="loadNode"
        @node-click="handleNodeClick"
      >
        <template #default="{ node, data }">
          <el-tooltip
            :content="`创建时间: ${formatTimestamp(data.base_info.created_at)}<br>更新时间: ${formatTimestamp(data.base_info.updated_at)}<br>描述: ${data.desc || '无'}<br>${data.node_type === NODE_TYPES.AGE ? `年龄范围: ${formatAgeRange(data.age_start, data.age_end)}<br>` : ''}费用: ${data.fee}元`"
            placement="top"
            :raw-content="true"
          >
            <div class="custom-tree-node">
              <div class="node-info">
                <el-image
                  v-if="data.pic"
                  :src="data.pic"
                  :preview-src-list="[data.pic]"
                  fit="cover"
                  class="node-image"
                  @click.stop
                  :preview-teleported="true"
                  :initial-index="0"
                  :hide-on-click-modal="false"
                ></el-image>
                <span class="node-name">{{ data.name }}</span>
                <el-tag 
                  size="small" 
                  :type="getNodeTypeTagType(data.node_type)"
                  class="node-type"
                >{{ getTypeNameById(data.node_type) }}</el-tag>
                <el-tag size="small" type="info" class="node-level">层级: {{ data.level }}</el-tag>
                <el-tag size="small" type="success" class="node-fee">{{ data.fee }}元</el-tag>
                <el-tag
                  v-if="data.gender && data.node_type === NODE_TYPES.AGE"
                  size="small"
                  type="warning"
                  class="node-gender"
                >{{ data.gender === 'male' ? '男' : '女' }}</el-tag>
                <el-tag
                  v-for="(tag, index) in getDisplayTags(data.tags)"
                  :key="index"
                  size="small"
                  class="node-tag"
                >{{ tag }}</el-tag>
                <el-button 
                  v-if="data.tags && data.tags.split(',').length > 3" 
                  size="small" 
                  @click.stop="showAllTags(data.tags)"
                >查看全部标签</el-button>
              </div>
              <div class="node-actions">
                <el-button size="small" @click="openCreateDialog(data)">添加子节点</el-button>
                <el-button size="small" @click="editNode(data)">编辑</el-button>
                <el-button size="small" type="danger" @click="removeNode(node, data)">删除</el-button>
              </div>
            </div>
          </el-tooltip>
        </template>
      </el-tree>
      <el-empty v-else description="暂无数据"></el-empty>
    </el-card>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="pagination"
    >
    </el-pagination>

    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="50%">
      <el-form :model="nodeForm" label-width="100px">
        <el-form-item label="名称">
          <el-input v-model="nodeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="nodeForm.node_type">
            <el-option
              v-for="type in classifyTypes"
              :key="type.value"
              :label="type.key"
              :value="type.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="nodeForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="性别" v-if="nodeForm.node_type === NODE_TYPES.AGE">
          <el-select v-model="nodeForm.gender" placeholder="请选择性别">
            <el-option label="不限性别" value=""></el-option>
            <el-option label="男" value="male"></el-option>
            <el-option label="女" value="female"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄范围" v-if="nodeForm.node_type === NODE_TYPES.AGE">
          <div class="age-input-group">
            <el-input-number 
              v-model="nodeForm.startAge" 
              :min="0" 
              :max="ageUnitIsYear ? 100 : 1200"
              :step="1"
              :precision="0"
              :placeholder="ageUnitIsYear ? '起始年龄(年)' : '起始年龄(月)'"
              @change="validateAgeRange">
            </el-input-number>
            <span class="mx-2">至</span>
            <el-input-number 
              v-model="nodeForm.endAge" 
              :min="0" 
              :max="ageUnitIsYear ? 100 : 1200"
              :step="1"
              :precision="0"
              :placeholder="ageUnitIsYear ? '结束年龄(年)' : '结束年龄(月)'"
              @change="validateAgeRange">
            </el-input-number>
            <el-switch
              v-model="ageUnitIsYear"
              active-text="年"
              inactive-text="月"
              class="ml-2"
              @change="handleAgeUnitChange"
            />
          </div>
          <div class="form-tip">{{ ageUnitIsYear ? '当前使用年为单位，提交时将自动转换为月' : '当前使用月为单位' }}</div>
        </el-form-item>
        <el-form-item label="所属老师" v-if="nodeForm.node_type === 3">
          <div class="teacher-select-area">
            <el-input 
              v-model="selectedTeacherName" 
              placeholder="点击选择老师" 
              readonly 
              @click="openTeacherDialog">
              <template #append>
                <el-button @click="openTeacherDialog">
                  <el-icon><Search /></el-icon>
                </el-button>
              </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="项目标签">
          <div class="tag-input-area">
            <div class="single-tag-input">
              <el-input 
                v-model="nodeForm.currentTag" 
                placeholder="输入标签"
                @keyup.enter="addTag">
              </el-input>
              <el-button @click="addTag">添加</el-button>
            </div>
            
            <el-collapse>
              <el-collapse-item title="批量添加标签">
                <el-input
                  type="textarea"
                  v-model="batchTags"
                  placeholder="多个标签用英文逗号分隔"
                  @change="handleBatchTags"
                ></el-input>
              </el-collapse-item>
            </el-collapse>
            
            <div class="tag-display">
              <el-tag
                v-for="tag in nodeForm.tagList"
                :key="tag"
                closable
                @close="removeTag(tag)"
                class="mx-1"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="费用">
          <el-input-number v-model="nodeForm.fee" :min="0"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitNode">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 老师选择对话框 -->
    <el-dialog title="选择老师" v-model="teacherDialogVisible" width="70%">
      <div class="teacher-dialog-content">
        <div class="search-bar">
          <el-form :inline="true" :model="teacherSearchForm">
            <el-form-item label="用户名">
              <el-input v-model="teacherSearchForm.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="teacherSearchForm.phone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchTeachers">搜索</el-button>
              <el-button @click="resetTeacherSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-table :data="teacherList" style="width: 100%">
          <el-table-column prop="base_info.id" label="ID" width="120"></el-table-column>
          <el-table-column label="头像" width="80">
            <template #default="scope">
              <el-avatar :size="40" :src="scope.row.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'">
              </el-avatar>
            </template>
          </el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="nickname" label="昵称"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button type="primary" size="small" @click="selectTeacher(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-container">
          <el-pagination
            v-model:current-page="teacherCurrentPage"
            v-model:page-size="teacherPageSize"
            :total="teacherTotal"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleTeacherSizeChange"
            @current-change="handleTeacherCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <!-- 在 template 中添加删除确认对话框 -->
    <el-dialog
      title="删除确认"
      v-model="deleteDialogVisible"
      width="400px"
      :close-on-click-modal="false"
      @closed="handleDeleteDialogClosed"
      class="delete-dialog"
    >
      <div class="delete-confirm-content">
        <div class="delete-info">
          <el-icon class="warning-icon"><WarningFilled /></el-icon>
          <span class="delete-title">确定要删除这个节点吗？</span>
          <p class="delete-node-name" v-if="currentDeleteNode">
            <el-icon><Document /></el-icon>
            {{ currentDeleteNode.name }}
          </p>
        </div>
        
        <el-divider></el-divider>
        
        <el-checkbox 
          v-model="forceDelete"
          class="force-delete-checkbox"
        >
          <el-icon><Delete /></el-icon>
          强制删除所有子节点和关联题目
        </el-checkbox>
        
        <div v-show="forceDelete" class="force-delete-warning">
          <el-alert
            :title="'警告：此操作将永久删除该节点' + 
                   (currentDeleteNode?.children?.length ? 
                   `及其 ${currentDeleteNode.children.length} 个子节点` : '') +
                   '和关联题目，不可恢复！'"
            type="error"
            :closable="false"
            show-icon
          />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">
            <el-icon><Close /></el-icon>
            取 消
          </el-button>
          <el-button 
            :type="forceDelete ? 'danger' : 'primary'"
            @click="confirmDelete"
            :loading="deleteLoading"
          >
            <el-icon v-if="!deleteLoading">
              <component :is="forceDelete ? 'Delete' : 'Check'" />
            </el-icon>
            <span>{{ forceDelete ? '确定强制删除' : '确定删除' }}</span>
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import { getConstantByKey, fetchAndCacheConstants } from '@/utils/constants'
import { Search } from '@element-plus/icons-vue'
import { NODE_TYPES } from '@/utils/constants'
import { 
  WarningFilled, 
  Document, 
  Delete, 
  Close, 
  Check 
} from '@element-plus/icons-vue'

export default {
  name: 'ClassifyManagement',
  components: {
    Search,
    WarningFilled,
    Document,
    Delete,
    Close,
    Check
  },
  setup() {
    const picFileList = ref([])
    const forceDelete = ref(false)
    
    const treeData = ref([])
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增节点')
    const nodeForm = reactive({
      base_info: {
        id: null,
      },
      name: '',
      node_type: '',
      parent_id: null,
      desc: '',
      gender: '',
      startAge: '',
      endAge: '',
      userId: '',
      pic: '',
      currentTag: '',
      tagList: [],
      fee: 0,
      sort: 0,
      status: 1
    })
    const classifyTypes = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const searchForm = reactive({
      search: '',
      parent_id: '',
      node_type: '',
      sortBy: 'id',
      sortDesc: false,
      minFee: null,
      maxFee: null,
      age_range: ''
    })

    const defaultProps = {
      children: 'children',
      label: 'name',
      hasChildren: true
    }

    const treeRef = ref(null)

    const loadNode = async (node, resolve) => {
      if (node.level === 0) {
        resolve(treeData.value)
        return
      }

      try {
        const parentId = node.data.base_info.id
        const res = await get(`/auth/admin/query/classify_nodes_by_parent/${parentId}`)
        if (res.data && res.data.nodes) {
          node.childNodes = []
          if (node.data.children) {
            node.data.children.length = 0
          }
          
          res.data.nodes.forEach(childNode => {
            treeRef.value.append(childNode, node)
          })
          
          resolve(res.data.nodes)
        } else {
          resolve([])
        }
      } catch (error) {
        console.error('加载子节点失败:', error)
        ElMessage.error('加载子节点失败')
        resolve([])
      }
    }

    const handleNodeClick = async (data, node) => {
      if (!node.expanded) {
        node.expanded = true
        if (!node.isLeaf) {
          try {
            const res = await get(`/auth/admin/query/classify_nodes_by_parent/${data.base_info.id}`)
            if (res.data && res.data.nodes) {
              node.childNodes = []
              if (node.data.children) {
                node.data.children.length = 0
              }
              
              res.data.nodes.forEach(childNode => {
                treeRef.value.append(childNode, node)
              })
            }
          } catch (error) {
            console.error('加载子节点失败:', error)
            ElMessage.error('加载子节点失败')
          }
        }
      } else {
        node.expanded = false
      }
    }

    const fetchTreeData = async () => {
      try {
        const params = {
          page: currentPage.value,
          size: pageSize.value,
          search: searchForm.search,
          node_type: searchForm.node_type,
          sort_by: searchForm.sortBy,
          sort_desc: searchForm.sortDesc,
          parent_id: ''
        }
        const res = await get('/auth/admin/classify_list', params)
        if (res.data) {
          treeData.value = (res.data.items || []).map(item => ({
            ...item,
            node_type: item.node_type,
            parent_id: item.parent_id
          }))
          total.value = res.data.total || 0
        }
      } catch (error) {
        console.error('获取分类树失败:', error)
        ElMessage.error('获取分类树失败')
        treeData.value = []
        total.value = 0
      }
    }

    const getTypeNameById = (nodeType) => {
      const typesData = getConstantByKey('classifyTypes')
      if (!typesData) return '未知类型'
      
      const type = typesData.find(t => t.value === nodeType)
      return type ? type.key : '未知类型'
    }

    const formatTimestamp = (timestamp) => {
      if (!timestamp) return '未知时间'
      const date = new Date(timestamp * 1000)
      return date.toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })
    }

    const openCreateDialog = (parentNode = null) => {
      dialogTitle.value = parentNode ? '新增子节点' : '新增根节点'
      Object.assign(nodeForm, {
        base_info: {
          id: null
        },
        name: '',
        node_type: '',
        parent_id: parentNode ? parentNode.base_info.id : null,
        desc: '',
        gender: '',
        startAge: '',
        endAge: '',
        userId: '',
        pic: '',
        currentTag: '',
        tagList: [],
        fee: 0,
        sort: 0,
        status: 1,
        age_start: nodeForm.node_type === NODE_TYPES.AGE ? nodeForm.age_start : '',
        age_end: nodeForm.node_type === NODE_TYPES.AGE ? nodeForm.age_end : '',
      })
      batchTags.value = ''
      picFileList.value = []
      dialogVisible.value = true
    }

    const editNode = async (data) => {
      dialogTitle.value = '编辑节点'
      
      // 处理年龄回显，根据当前单位模式转换
      const startAge = data.age_start || 0
      const endAge = data.age_end || 0
      const tagList = data.tags ? data.tags.split(',').filter(tag => tag.trim()) : []
      
      Object.assign(nodeForm, {
        base_info: {
          id: data.base_info.id
        },
        name: data.name,
        node_type: data.node_type,
        parent_id: data.parent_id,
        desc: data.desc,
        gender: data.gender || '',
        startAge: ageUnitIsYear.value ? Math.floor(startAge / 12) : startAge,
        endAge: ageUnitIsYear.value ? Math.floor(endAge / 12) : endAge,
        userId: data.user_id || '',  // 使用 user_id
        pic: data.pic,
        currentTag: '',
        tagList: tagList,
        fee: data.fee,
        sort: data.sort || 0,
        status: data.status || 1
      })
      
      batchTags.value = tagList.join(',')
      picFileList.value = nodeForm.pic ? [{ name: '当前图片', url: nodeForm.pic }] : []
      
      // 如果是老师节点且有user_id，获取老师信息并显示
      if (data.node_type === 3 && data.user_id) {
        try {
          // 获取老师详细信息
          const res = await post('/auth/admin/query/query_users', {
            page: 1,
            size: 1,
            identity: 3,
            id: data.user_id  // 通过ID精确查询
          })
          if (res.data && res.data.list && res.data.list.length > 0) {
            const teacher = res.data.list[0]
            selectedTeacherName.value = teacher.username
          } else {
            selectedTeacherName.value = ''
            ElMessage.warning('未找到关联的老师信息')
          }
        } catch (error) {
          console.error('获取老师信息失败:', error)
          ElMessage.warning('获取老师信息失败')
          selectedTeacherName.value = ''
        }
      } else {
        selectedTeacherName.value = ''
      }
      
      dialogVisible.value = true
    }

    const deleteDialogVisible = ref(false)
    const deleteLoading = ref(false)
    const currentDeleteNode = ref(null)
    
    const removeNode = (node, data) => {
      currentDeleteNode.value = data
      deleteDialogVisible.value = true
    }
    
    const confirmDelete = async () => {
      if (!currentDeleteNode.value) return
      
      try {
        deleteLoading.value = true
        await del(`/auth/admin/classify_node/${currentDeleteNode.value.base_info.id}`, {
          force_delete: forceDelete.value
        })
        ElMessage.success('删除成功')
        deleteDialogVisible.value = false
        fetchTreeData()
      } catch (error) {
        console.error('删除失败:', error)
        ElMessage.error('删除失败')
      } finally {
        deleteLoading.value = false
      }
    }
    
    const handleDeleteDialogClosed = () => {
      forceDelete.value = false
      currentDeleteNode.value = null
      deleteLoading.value = false
    }

    const submitNode = async () => {
      if (nodeForm.node_type === NODE_TYPES.AGE && !validateAgeRange()) {
        return
      }

      try {
        const submitData = {
          parent_id: nodeForm.parent_id,
          name: nodeForm.name,
          node_type: nodeForm.node_type,
          user_id: nodeForm.userId,
          desc: nodeForm.desc,
          pic: nodeForm.pic,
          tags: nodeForm.tagList.join(','),
          fee: nodeForm.fee,
          sort: nodeForm.sort,
          status: nodeForm.status
        }

        if (nodeForm.node_type === NODE_TYPES.AGE) {
          submitData.age_start = ageUnitIsYear.value ? nodeForm.startAge * 12 : nodeForm.startAge
          submitData.age_end = ageUnitIsYear.value ? nodeForm.endAge * 12 : nodeForm.endAge
          submitData.gender = nodeForm.gender
        }

        if (nodeForm.base_info.id) {
          await put(`/auth/admin/classify_node/${nodeForm.base_info.id}`, submitData)
          fetchTreeData()
          ElMessage.success('更新成功')
        } else {
          await post('/auth/admin/classify_node', submitData)
          ElMessage.success('创建成功')
          fetchTreeData()
        }
        dialogVisible.value = false
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }


    const searchNodes = () => {
      currentPage.value = 1
      fetchTreeData()
    }

    const resetSearch = () => {
      Object.assign(searchForm, {
        search: '',
        parent_id: '',
        node_type: '',
        sort_by: 'id',
        sort_desc: false,
        min_fee: null,
        max_fee: null,
        age_range: ''
      })
      currentPage.value = 1
      fetchTreeData()
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      fetchTreeData()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      fetchTreeData()
    }

    const getDisplayTags = (tags) => {
      if (!tags) return []
      const tagArray = tags.split(',')
      return tagArray.slice(0, 3)
    }

    const showAllTags = (tags) => {
      ElMessageBox.alert(tags, '所有标签', {
        confirmButtonText: '确定'
      })
    }

    const handleAvatarSuccess = (response) => {
      console.log('Upload response:', response)
      if (response.code === 200 && response.data && response.data.url) {
        nodeForm.pic = response.data.url
        picFileList.value = [{ name: '当前图片', url: response.data.url }]
        ElMessage.success('图片上传成功')
      } else {
        console.error('Invalid response from file upload:', response)
        ElMessage.error(response.msg || '图片上传失败，请重试')
      }
    }

    const fileTypes = ref(getConstantByKey('fileTypes') || [])
    const getFileTypeValue = (typeName) => {
      const fileTypes = getConstantByKey('fileTypes')
      if (!fileTypes || fileTypes.length === 0) {
        console.error('Failed to load fileTypes')
        ElMessage.error('文件类型配置加载失败')
        return null
      }

      const type = fileTypes.find(t => t.key === typeName)
      if (!type) {
        console.error(`File type "${typeName}" not found`)
        ElMessage.error('未找到指定的文件类型')
        return null
      }

      return type.value
    }

    const batchTags = ref('')

    const addTag = () => {
      if (!nodeForm.currentTag.trim()) return
      
      if (!nodeForm.tagList.includes(nodeForm.currentTag.trim())) {
        nodeForm.tagList.push(nodeForm.currentTag.trim())
        batchTags.value = nodeForm.tagList.join(',')
      }
      nodeForm.currentTag = ''
    }

    const removeTag = (tag) => {
      nodeForm.tagList = nodeForm.tagList.filter(t => t !== tag)
      batchTags.value = nodeForm.tagList.join(',')
    }

    const handleBatchTags = (value) => {
      if (!value) {
        nodeForm.tagList = []
        return
      }
      const tags = value.split(',')
        .map(tag => tag.trim())
        .filter(tag => tag)
      
      nodeForm.tagList = [...new Set(tags)]
      batchTags.value = nodeForm.tagList.join(',')
    }

    const validateAgeRange = () => {
      if (nodeForm.startAge === '' || nodeForm.endAge === '') {
        return true // 允许为空
      }
      
      const start = parseInt(nodeForm.startAge)
      const end = parseInt(nodeForm.endAge)
      
      if (isNaN(start) || isNaN(end)) {
        ElMessage.error('年龄必须是数字')
        return false
      }
      
      const maxAge = ageUnitIsYear.value ? 100 : 1200 // 最大100年或1200个月
      
      if (start < 0 || end < 0) {
        ElMessage.error('年龄不能为负数')
        return false
      }
      
      if (start > maxAge || end > maxAge) {
        ElMessage.error(`年龄不能超过${ageUnitIsYear.value ? '100年' : '1200个月'}`)
        return false
      }
      
      if (start > end) {
        ElMessage.error('起始年龄不能大于结束年龄')
        return false
      }
      
      return true
    }

    const ageUnitIsYear = ref(false)
    const teacherList = ref([])
    const teacherLoading = ref(false)

    const fetchTeacherList = async (query = '') => {
      try {
        teacherLoading.value = true
        const res = await post('/auth/admin/query/query_users', {
          username: query,
          phone: query,
          identity: 3,
          page: 0,
          size: 0
        })
        if (res.data && res.data.list) {
          teacherList.value = res.data.list
        }
      } catch (error) {
        console.error('获取老师列表失败:', error)
        ElMessage.error('获取老师列表失败')
      } finally {
        teacherLoading.value = false
      }
    }

    const searchTeachers = async () => {
      try {
        // 构建查询参数，为模糊搜索添加 % 符号
        const params = {
          identity: 3, // 老师身份
          page: teacherCurrentPage.value,
          size: teacherPageSize.value,
          phone: teacherSearchForm.phone ? `%${teacherSearchForm.phone}%` : '',
          username: teacherSearchForm.username ? `%${teacherSearchForm.username}%` : ''
        }
        
        const res = await post('/auth/admin/query/query_users', params)
        if (res.data) {
          teacherList.value = res.data.list
          teacherTotal.value = res.data.total
        }
      } catch (error) {
        console.error('获取老师列表失败:', error)
        ElMessage.error('获取老师列表失败')
      }
    }

    const handleAgeUnitChange = () => {
      if (nodeForm.startAge !== '') {
        nodeForm.startAge = ageUnitIsYear.value 
          ? Math.floor(nodeForm.startAge / 12) 
          : nodeForm.startAge * 12
      }
      if (nodeForm.endAge !== '') {
        nodeForm.endAge = ageUnitIsYear.value 
          ? Math.floor(nodeForm.endAge / 12) 
          : nodeForm.endAge * 12
      }
    }

    // 老师选择相关的响应式数据
    const teacherDialogVisible = ref(false)
    const selectedTeacherName = ref('')
    const teacherSearchForm = reactive({
      username: '',
      phone: ''
    })
    const teacherCurrentPage = ref(1)
    const teacherPageSize = ref(10)
    const teacherTotal = ref(0)

    // 打开老师选择对话框
    const openTeacherDialog = () => {
      teacherDialogVisible.value = true
      searchTeachers()
    }

    // 重置老师搜索
    const resetTeacherSearch = () => {
      teacherSearchForm.username = ''
      teacherSearchForm.phone = ''
      teacherCurrentPage.value = 1
      searchTeachers()
    }

    // 选择老师
    const selectTeacher = (teacher) => {
      nodeForm.userId = teacher.base_info.id
      selectedTeacherName.value = teacher.username
      teacherDialogVisible.value = false
    }

    // 处理分页变化
    const handleTeacherSizeChange = (val) => {
      teacherPageSize.value = val
      searchTeachers()
    }

    const handleTeacherCurrentChange = (val) => {
      teacherCurrentPage.value = val
      searchTeachers()
    }

    // 添加格式化年龄范围的函数
    const formatAgeRange = (start, end) => {
      if (start === undefined || end === undefined) return '未设置'
      if (start === 0 && end === 0) return '不限年龄'
      
      const formatAge = (months) => {
        if (months >= 12) {
          const years = Math.floor(months / 12)
          const remainingMonths = months % 12
          return remainingMonths > 0 
            ? `${years}岁${remainingMonths}个月` 
            : `${years}岁`
        }
        return `${months}个月`
      }
      
      return `${formatAge(start)} - ${formatAge(end)}`
    }

    // 添加节点类型标签颜色映射函数
    const getNodeTypeTagType = (nodeType) => {
      const typeMap = {
        [NODE_TYPES.ROOT]: 'warning',    // 修改根节点标签类型为 warning（橙色）
        [NODE_TYPES.PROJECT]: 'success',  // 绿色
        [NODE_TYPES.MODULE]: 'warning',   // 黄色
        [NODE_TYPES.TEACHER]: 'danger',   // 红色
        [NODE_TYPES.AGE]: 'primary',      // 蓝色
        [NODE_TYPES.MAJOR]: '',           // 默认颜色
        [NODE_TYPES.PAPER]: 'warning'     // 黄色
      }
      return typeMap[nodeType] || ''
    }


    // 添加处理强制删除状态变化的方法
    const handleForceDeleteChange = () => {
      // 重新打开对话框以更新按钮文字和样式
      const currentDialog = document.querySelector('.el-message-box')
      if (currentDialog) {
        const confirmBtn = currentDialog.querySelector('.el-button--primary')
        if (confirmBtn) {
          confirmBtn.textContent = forceDelete.value ? '确定强制删除' : '确定删除'
          confirmBtn.className = forceDelete.value 
            ? 'el-button el-button--primary el-button--small force-delete-btn'
            : 'el-button el-button--primary el-button--small'
        }
        currentDialog.className = forceDelete.value 
          ? 'el-message-box delete-confirm-dialog force-delete-active'
          : 'el-message-box delete-confirm-dialog'
      }
    }

    onMounted(async () => {
      try {
        await fetchAndCacheConstants()
        const typesData = getConstantByKey('classifyTypes')
        classifyTypes.value = typesData || []
        fileTypes.value = getConstantByKey('fileTypes') || []
        await fetchTeacherList()
        fetchTreeData()
      } catch (error) {
        console.error('Failed to load data:', error)
        ElMessage.error('加载数据失败')
      }
    })

    return {
      treeData,
      dialogVisible,
      dialogTitle,
      nodeForm,
      classifyTypes,
      currentPage,
      pageSize,
      total,
      searchForm,
      openCreateDialog,
      editNode,
      removeNode,
      submitNode,
      getTypeNameById,
      formatTimestamp,
      searchNodes,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      defaultProps,
      getDisplayTags,
      showAllTags,
      handleAvatarSuccess,
      fileTypes,
      getFileTypeValue,
      picFileList,
      loadNode,
      handleNodeClick,
      treeRef,
      addTag,
      removeTag,
      handleBatchTags,
      validateAgeRange,
      batchTags,
      ageUnitIsYear,
      teacherList,
      teacherLoading,
      searchTeachers,
      handleAgeUnitChange,
      teacherDialogVisible,
      selectedTeacherName,
      teacherSearchForm,
      teacherCurrentPage,
      teacherPageSize,
      teacherTotal,
      openTeacherDialog,
      resetTeacherSearch,
      selectTeacher,
      handleTeacherSizeChange,
      handleTeacherCurrentChange,
      formatAgeRange,
      NODE_TYPES,
      getNodeTypeTagType,
      forceDelete,
      handleForceDeleteChange,
      deleteDialogVisible,
      deleteLoading,
      confirmDelete,
      handleDeleteDialogClosed,
    }
  }
}
</script>

<style scoped>
.classify-management {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  align-items: center;
}
.search-form .el-form-item {
  margin-bottom: 0;
  margin-right: 1rem;
}
.button-area {
  display: flex;
  gap: 1rem;
}
.tree-card {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.pagination {
  margin-top: auto;
  padding: 1rem 0;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 0;
  width: 100%;
}
.node-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.node-name {
  font-size: 16px;
  font-weight: bold;
}
.node-type, .node-level {
  font-size: 12px;
}
.node-actions {
  display: flex;
  gap: 10px;
}
:deep(.el-tree-node__content) {
  height: auto;
  padding: 5px 0;
}
:deep(.el-tree-node) {
  position: relative;
}
:deep(.el-tree-node__children) {
  padding-left: 20px;
}
:deep(.el-tree-node__expand-icon) {
  padding: 12px;
}
:deep(.el-tree) {
  background-color: transparent;
}
:deep(.el-tree-node__content:hover) {
  background-color: #323334;
}
.node-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;
  z-index: 1;
}

.node-image:hover {
  transform: scale(1.1);
  z-index: 2;
}

.node-fee {
  margin-left: 10px;
}
.node-tag {
  margin-left: 5px;
}
.avatar-uploader .avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}
.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
}
:deep(.el-image-viewer__wrapper) {
  z-index: 2100;
}
.tag-input-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.single-tag-input {
  display: flex;
  gap: 10px;
}

.tag-display {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.form-tip {
  font-size: 12px;
  color: #909399;
  margin-top: 4px;
}

.mx-2 {
  margin: 0 8px;
}

.age-input-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.ml-2 {
  margin-left: 8px;
}

.teacher-select-area {
  width: 100%;
}

.teacher-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.teacher-name {
  font-weight: bold;
}

.teacher-phone {
  color: #909399;
  font-size: 12px;
}

:deep(.el-select) {
  width: 100%;
}

.teacher-dialog-content {
  .search-bar {
    margin-bottom: 20px;
  }

  .pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}

.teacher-select-area {
  width: 100%;
  
  :deep(.el-input-group__append) {
    padding: 0;
    
    .el-button {
      margin: 0;
      border: none;
      height: 100%;
      padding: 0 15px;
    }
  }
}

/* 添加性别标签样式 */
.node-gender {
  margin-left: 5px;
  background-color: #e6a23c;
  color: white;
}

.node-type {
  margin-left: 8px;
  font-weight: bold;
}

/* 为不同类型的节点添加特定的样式 */
.node-type.el-tag--success {
  background-color: var(--el-color-success-light-9);
  border-color: var(--el-color-success);
  color: var(--el-color-success);
}

.node-type.el-tag--warning {
  background-color: var(--el-color-warning-light-9);
  border-color: var(--el-color-warning);
  color: var(--el-color-warning);
}

.node-type.el-tag--danger {
  background-color: var(--el-color-danger-light-9);
  border-color: var(--el-color-danger);
  color: var(--el-color-danger);
}

.node-type.el-tag--primary {
  background-color: var(--el-color-primary-light-9);
  border-color: var(--el-color-primary);
  color: var(--el-color-primary);
}

.node-type.el-tag--info {
  background-color: var(--el-color-info-light-9);
  border-color: var(--el-color-info);
  color: var(--el-color-info);
}

/* 删除确认对话框样式 */
.delete-dialog {
  :deep(.el-dialog__header) {
    display: none;
  }
  
  :deep(.el-dialog__body) {
    padding: 20px;
  }
}

.delete-confirm-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  .delete-info {
    display: flex;
    align-items: center;
    gap: 8px;
    
    .warning-icon {
      font-size: 24px;
      color: var(--el-color-warning);
    }
    
    .delete-title {
      font-size: 16px;
      color: var(--el-text-color-primary);
    }
    
    .delete-node-name {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--el-text-color-secondary);
      font-size: 14px;
      margin: 0 0 0 8px;
      
      .el-icon {
        font-size: 14px;
      }
    }
  }
  
  .el-divider {
    margin: 12px 0;
  }
  
  .force-delete-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 4px 0;
    
    :deep(.el-checkbox__label) {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--el-text-color-regular);
      
      .el-icon {
        color: var(--el-color-danger);
      }
    }
    
    &.is-checked {
      :deep(.el-checkbox__label) {
        color: var(--el-color-danger);
        font-weight: 500;
      }
    }
  }
  
  .force-delete-warning {
    margin-top: 8px;
    
    :deep(.el-alert) {
      border-radius: 4px;
      
      .el-alert__title {
        font-size: 13px;
        line-height: 1.4;
      }
      
      .el-alert__icon {
        font-size: 16px;
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 8px;
  
  .el-button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 16px;
    
    .el-icon {
      font-size: 14px;
    }
  }
}
</style>
