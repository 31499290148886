<template>
  <div class="permission-list">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="权限名称">
              <el-input v-model="searchForm.name" placeholder="权限名称"></el-input>
            </el-form-item>
            <el-form-item label="权限类型">
              <el-select v-model="searchForm.permissionType" placeholder="选择权限类型">
                <el-option label="全部" value=""></el-option>
                <el-option label="公共权限" value="public"></el-option>
                <el-option label="认证权限" value="auth"></el-option>
                <el-option label="管理权限" value="admin"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="HTTP方法">
              <el-select v-model="searchForm.method" placeholder="选择HTTP方法">
                <el-option label="全部" value=""></el-option>
                <el-option label="GET" value="GET"></el-option>
                <el-option label="POST" value="POST"></el-option>
                <el-option label="PUT" value="PUT"></el-option>
                <el-option label="DELETE" value="DELETE"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchPermissions">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-collapse v-model="activeGroups">
        <el-collapse-item 
          v-for="group in filteredPermissions" 
          :key="group.permission_type"
          :name="group.permission_type"
        >
          <template #title>
            <div class="group-header">
              <el-tag :type="getPermissionTypeTag(group.permission_type)" effect="dark">
                {{ formatPermissionType(group.permission_type) }}
              </el-tag>
              <span class="permission-count">
                ({{ group.permissions.length }}个权限)
              </span>
            </div>
          </template>

          <el-table :data="group.permissions" style="width: 100%">
            <el-table-column prop="name" label="权限名称" min-width="180">
              <template #default="scope">
                <el-tooltip :content="scope.row.description" placement="top">
                  <span>{{ scope.row.name }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="path" label="API路径" min-width="200"></el-table-column>
            <el-table-column prop="method" label="HTTP方法" width="100">
              <template #default="scope">
                <el-tag :type="getMethodTagType(scope.row.method)" size="small">
                  {{ scope.row.method }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="80">
              <template #default="scope">
                <el-tag :type="scope.row.status === 1 ? 'success' : 'danger'" size="small">
                  {{ scope.row.status === 1 ? '启用' : '禁用' }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { get } from '@/utils/request'

export default {
  name: 'PermissionList',
  setup() {
    const searchForm = reactive({
      name: '',
      permissionType: '',
      method: ''
    })
    
    const permissions = ref([])
    const activeGroups = ref(['public', 'auth', 'admin'])

    const getMethodTagType = (method) => {
      const types = {
        'GET': 'success',
        'POST': 'primary',
        'PUT': 'warning',
        'DELETE': 'danger'
      }
      return types[method] || 'info'
    }

    const getPermissionTypeTag = (type) => {
      const types = {
        'public': 'info',
        'auth': 'warning',
        'admin': 'danger'
      }
      return types[type] || 'info'
    }

    const formatPermissionType = (type) => {
      const typeMap = {
        'public': '公共权限',
        'auth': '认证权限',
        'admin': '管理权限'
      }
      return typeMap[type] || type
    }

    const filteredPermissions = computed(() => {
      if (!permissions.value?.groups) return []
      
      return permissions.value.groups.map(group => {
        let filteredPerms = [...group.permissions]
        
        if (searchForm.name) {
          filteredPerms = filteredPerms.filter(p => 
            p.name.toLowerCase().includes(searchForm.name.toLowerCase()) ||
            p.path.toLowerCase().includes(searchForm.name.toLowerCase())
          )
        }
        
        if (searchForm.method) {
          filteredPerms = filteredPerms.filter(p => p.method === searchForm.method)
        }
        
        if (searchForm.permissionType) {
          if (group.permission_type !== searchForm.permissionType) {
            filteredPerms = []
          }
        }

        return {
          ...group,
          permissions: filteredPerms
        }
      }).filter(group => group.permissions.length > 0)
    })

    const searchPermissions = async () => {
      try {
        const res = await get('/auth/admin/permissions_by_type')
        if (res.data) {
          permissions.value = res.data
        }
      } catch (error) {
        console.error('获取权限列表失败:', error)
        ElMessage.error('获取权限列表失败')
        permissions.value = []
      }
    }

    const resetSearch = () => {
      searchForm.name = ''
      searchForm.permissionType = ''
      searchForm.method = ''
      searchPermissions()
    }

    // 初始化加载
    searchPermissions()

    return {
      searchForm,
      activeGroups,
      filteredPermissions,
      searchPermissions,
      resetSearch,
      getMethodTagType,
      getPermissionTypeTag,
      formatPermissionType
    }
  }
}
</script>

<style scoped>
.permission-list {
  height: 100%;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.group-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.permission-count {
  color: #909399;
  font-size: 14px;
}
</style>