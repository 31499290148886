<template>
  <div class="topic-management">
    <el-container>
      <!-- 左侧树形结构 -->
      <el-aside width="360px" class="tree-aside">
        <el-card class="tree-card" :body-style="{ padding: '0px' }">
          <el-scrollbar>
            <el-tree
              ref="treeRef"
              :data="classifyTree"
              :props="defaultProps"
              node-key="id"
              :expand-on-click-node="false"
              highlight-current
              lazy
              :load="loadNode"
              @node-click="handleNodeClick"
              class="custom-tree"
            >
              <template #default="{ node, data }">
                <el-tooltip 
                    :content="node.label" 
                    placement="right" 
                    :show-after="200"
                    :hide-after="3000"
                  >
                <div class="custom-tree-node">
                  <el-icon :class="getNodeIconClass(data.node_type)">
                    <Document v-if="data.node_type === NODE_TYPES.PAPER" />
                    <Folder v-else-if="data.node_type === NODE_TYPES.PROJECT" />
                    <Collection v-else-if="data.node_type === NODE_TYPES.MODULE" />
                    <User v-else-if="data.node_type === NODE_TYPES.TEACHER" />
                    <Timer v-else-if="data.node_type === NODE_TYPES.AGE" />
                    <Reading v-else-if="data.node_type === NODE_TYPES.MAJOR" />
                    <FolderOpened v-else />
                  </el-icon>
                  <span class="node-type-tag" :class="getNodeTypeTagClass(data.node_type)">
                    {{ getNodeTypeName(data.node_type) }}
                  </span>
                    <span class="node-text">{{ node.label }}</span>
                </div>
              </el-tooltip>
              </template>
            </el-tree>
          </el-scrollbar>
        </el-card>
      </el-aside>

      <!-- 修改右侧内容区的布局结构 -->
      <el-main class="topic-main">
        <!-- 面包屑导航 -->
        <el-breadcrumb v-if="currentPath.length > 0" class="breadcrumb">
          <el-breadcrumb-item 
            v-for="item in currentPath" 
            :key="item.id"
            class="breadcrumb-item"
            @click.prevent="handleBreadcrumbClick(item)"
          >
            {{ item.name }}
          </el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 搜索区域 -->
        <el-card class="search-card">
          <div class="top-bar">
            <div class="search-area">
              <el-form :inline="true" :model="searchForm" class="search-form">
                <el-form-item label="标题" style="width: 200px;">
                  <el-input v-model="searchForm.title" placeholder="题目标题"></el-input>
                </el-form-item>
                <el-form-item label="题目类型" style="width: 200px;">
                  <el-select v-model="searchForm.topic_type" placeholder="选择题目类型">
                    <el-option
                      v-for="type in topicTypes"
                      :key="type.value"
                      :label="type.key"
                      :value="type.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态" style="width: 200px;">
                  <el-select v-model="searchForm.status" placeholder="选择状态">
                    <el-option label="启用" :value="1"></el-option>
                    <el-option label="禁用" :value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="button-area">
              <el-button type="primary" @click="searchTopics">查询</el-button>
              <el-button @click="resetSearch">重置</el-button>
              <el-button type="warning" @click="openExportDialog">导出题目</el-button>
              <el-button type="success" @click="openCreateDialog">新增题目</el-button>
            </div>
          </div>
        </el-card>

        <!-- 表格区域 - 添加 flex-grow -->
        <el-card class="table-card">
          <el-table v-if="topics && topics.length > 0" :data="topics" style="width: 100%">
            <el-table-column prop="title" label="题目名称"></el-table-column>
            <el-table-column prop="topic_type" label="题目类型">
              <template #default="scope">
                <div class="topic-type-tag">
                  <el-icon :class="getTopicTypeIconClass(scope.row.topic_type)">
                    <Edit v-if="scope.row.topic_type === getTopicTypeValue('单选题')" />
                    <Select v-else-if="scope.row.topic_type === getTopicTypeValue('多选题')" />
                    <Switch v-else-if="scope.row.topic_type === getTopicTypeValue('判断题')" />
                    <Rank v-else-if="scope.row.topic_type === getTopicTypeValue('打分制题')" />
                    <EditPen v-else-if="scope.row.topic_type === getTopicTypeValue('问答题')" />
                    <Document v-else />
                  </el-icon>
                  <span :class="getTopicTypeTextClass(scope.row.topic_type)">
                    {{ getTopicTypeName(scope.row.topic_type) }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="所属分类">
              <template #default="scope">
                <el-tag
                  v-for="classify in scope.row.classify_list"
                  :key="classify.classify_id"
                  class="mr-1 mb-1"
                >
                  {{ classify.classify_name }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="题目选项">
              <template #default="scope">
                <el-button 
                  v-if="scope.row.option_list && scope.row.option_list.length > 0"
                  link 
                  type="primary" 
                  @click="showOptions(scope.row.option_list)"
                >
                  查看选项
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template #default="scope">
                <el-tag :type="scope.row.status === 1 ? 'success' : 'danger'">
                  {{ scope.row.status === 1 ? '启用' : '禁用' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="创建时间">
              <template #default="scope">
                {{ formatDate(scope.row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template #default="scope">
                <el-button link type="primary" @click="showMoreInfo(scope.row)">更多信息</el-button>
                <el-button link type="primary" @click="editTopic(scope.row)">编辑</el-button>
                <el-button link type="danger" @click="deleteTopic(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-empty v-else description="暂无数据"></el-empty>
        </el-card>

        <!-- 分页区域 -->
        <div class="pagination-container">
          <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>

        <!-- 新增/编辑题目对话框 -->
        <el-dialog
          :title="dialogTitle"
          v-model="dialogVisible"
          width="60%"
          :close-on-click-modal="false"
          :before-close="handleDialogClose"
        >
          <el-scrollbar height="60vh">
            <el-form :model="topicForm" label-width="120px">
              <el-divider content-position="left">基本信息</el-divider>
              <el-form-item label="标题" required>
                <el-input v-model="topicForm.title"></el-input>
              </el-form-item>

              <el-divider content-position="left">项目信息</el-divider>
              <el-form-item label="题目类型" required>
                <el-select v-model="topicForm.topic_type" @change="handleTopicTypeChange">
                  <el-option
                    v-for="type in topicTypes"
                    :key="type.value"
                    :label="type.key"
                    :value="type.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- 所属项目选择 -->
              <el-form-item label="所属项目" required>
                <div class="project-selection">
                  <el-cascader
                    v-model="selectedClassify"
                    :options="cascaderClassifyTree"
                    :props="{
                      checkStrictly: false,
                      emitPath: false,
                    }"
                    clearable
                    @change="handleClassifyChange"
                    placeholder="请选择所属项目"
                  ></el-cascader>
                  <div v-if="topicForm.classify_id" class="selected-classify">
                    当前选择: {{ getClassifyName(topicForm.classify_id) }}
                  </div>
                </div>
              </el-form-item>

              <!-- 选择题和判断题的选项 -->
              <template v-if="topicForm.topic_type === getTopicTypeValue('单选题') || topicForm.topic_type === getTopicTypeValue('多选题') || topicForm.topic_type === getTopicTypeValue('判断题')">
                <el-form-item
                  v-for="(option, index) in topicForm.options"
                  :key="index"
                  :label="`选项 ${index + 1}`"
                >
                  <div class="option-row">
                    <el-input 
                      v-model="option.option" 
                      style="width: 40%;"
                      :placeholder="topicForm.topic_type === getTopicTypeValue('判断题') ? '是/否' : '选项内容'"
                      :disabled="topicForm.topic_type === getTopicTypeValue('判断题')"
                    ></el-input>
                    <el-select 
                      v-model="option.option_type" 
                      style="width: 20%;"
                      :disabled="topicForm.topic_type === getTopicTypeValue('判断题')"
                    >
                      <el-option
                        v-for="type in optionTypes"
                        :key="type.value"
                        :label="type.key"
                        :value="type.value"
                      ></el-option>
                    </el-select>
                    <el-input-number 
                      v-model="option.score" 
                      :min="0"
                      :max="100"
                      style="width: 30%;"
                      placeholder="选项分数"
                    >
                      <template #prefix>
                        <span class="score-prefix">分数:</span>
                      </template>
                    </el-input-number>
                    <el-button 
                      @click="removeOption(index)" 
                      type="danger" 
                      :disabled="topicForm.topic_type === getTopicTypeValue('判断题')"
                    >
                      <el-icon><Delete /></el-icon>
                    </el-button>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button 
                    @click="addOption" 
                    type="primary" 
                    icon="el-icon-plus"
                    v-if="topicForm.topic_type !== getTopicTypeValue('判断题')"
                  >添加选项</el-button>
                </el-form-item>
              </template>
              
              <!-- 打分题的分数范围 -->
              <el-form-item v-if="topicForm.topic_type === getTopicTypeValue('打分制题')" label="分数范围">
                <div v-if="dialogVisible">
                  <el-slider
                    v-model="topicForm.score_range"
                    range
                    :min="0"
                    :max="3000"
                    :step="1"
                    show-input
                    input-size="small"
                    style="width: 200px;"
                    @change="updateScoreRange"
                  ></el-slider>
                </div>
              </el-form-item>
              
              <el-divider content-position="left">附加资源</el-divider>
              <!-- 附件上传区域 -->
              <el-collapse v-model="activeCollapse">
                <el-collapse-item title="附件上传" name="attachments" class="attachment-collapse">
                  <template #title>
                    <div class="attachment-header">
                      <span>附件上传</span>
                      <el-badge 
                        :value="getAttachmentCount()" 
                        :hidden="getAttachmentCount() === 0"
                        class="attachment-badge"
                      >
                        <el-tag type="warning" effect="dark" class="expand-hint">
                          <el-icon class="expand-icon"><ArrowDown /></el-icon>
                          点击展开
                        </el-tag>
                      </el-badge>
                    </div>
                  </template>
                  <el-form-item label="题目图片">
                    <FileUpload
                      ref="imageUploadRef"
                      v-model="topicForm.pictures"
                      :fileType="FILE_TYPE.IMAGE"
                      :businessType="FILE_BUSINESS_TYPES.QUESTION_IMAGE"
                      accept="image/*"
                      :multiple="true"
                      :limit="5"
                      :maxSize="10"
                      tip="支持jpg、png格式，单个文件不超过10MB"
                      @on-success="handlePictureUploadSuccess"
                      @on-remove="handlePictureRemove"
                      @on-sort="handlePictureSort"
                    />
                  </el-form-item>
                  <el-form-item label="上传视频">
                    <FileUpload
                      ref="videoUploadRef"
                      v-model="topicForm.video"
                      :fileType="FILE_TYPE.VIDEO"
                      :businessType="FILE_BUSINESS_TYPES.QUESTION_VIDEO"
                      accept="video/*"
                      :multiple="false"
                      :limit="1"
                      :maxSize="100"
                      tip="支持常见视频格式，大小不超过100MB"
                      @on-success="handleVideoUploadSuccess"
                      @on-remove="handleVideoRemove"
                    >
                      <template #preview="{ file }">
                        <video v-if="file.url" :src="file.url" controls style="max-width: 300px;"></video>
                      </template>
                    </FileUpload>
                  </el-form-item>
                  <el-form-item label="上传音频">
                    <FileUpload
                      ref="audioUploadRef"
                      v-model="topicForm.audio"
                      :fileType="FILE_TYPE.AUDIO"
                      :businessType="FILE_BUSINESS_TYPES.QUESTION_AUDIO"
                      accept="audio/*"
                      :multiple="false"
                      :limit="1"
                      :maxSize="50"
                      tip="支持常见音频格式，大小不超过50MB"
                      @on-success="handleAudioUploadSuccess"
                      @on-remove="handleAudioRemove"
                    >
                      <template #preview="{ file }">
                        <audio v-if="file.url" :src="file.url" controls style="max-width: 300px;"></audio>
                      </template>
                    </FileUpload>
                  </el-form-item>
                  <el-form-item label="上传文件">
                    <FileUpload
                      ref="fileUploadRef"
                      v-model="topicForm.file"
                      :fileType="FILE_TYPE.DOCUMENT"
                      :businessType="FILE_BUSINESS_TYPES.QUESTION_FILE"
                      accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                      :multiple="false"
                      :limit="1"
                      :maxSize="100"
                      tip="支持PDF、Word、Excel、txt格式，大小不超过100MB"
                      @on-success="handleFileUploadSuccess"
                      @on-remove="handleFileRemove"
                    />
                  </el-form-item>
                </el-collapse-item>
              </el-collapse>

              <!-- 标签管理 -->
              <el-form-item label="题目标签">
                <div class="tag-input-area">
                  <div class="single-tag-input">
                    <el-input 
                      v-model="topicForm.currentTag" 
                      placeholder="输入标签"
                      @keyup.enter="addTag"
                    >
                    </el-input>
                    <el-button @click="addTag">添加</el-button>
                  </div>
                  
                  <el-collapse>
                    <el-collapse-item title="批量添加标签">
                      <el-input
                        type="textarea"
                        v-model="batchTags"
                        placeholder="多个标签用英文逗号分隔"
                        @change="handleBatchTags"
                      ></el-input>
                    </el-collapse-item>
                  </el-collapse>
                  
                  <div class="tag-display">
                    <el-tag
                      v-for="tag in topicForm.tagList"
                      :key="tag"
                      closable
                      @close="removeTag(tag)"
                      class="mx-1"
                    >
                      {{ tag }}
                    </el-tag>
                  </div>
                </div>
              </el-form-item>

              <el-divider content-position="left">其他设置</el-divider>
              <el-form-item label="状态">
                <el-switch
                  v-model="topicForm.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="启用"
                  inactive-text="禁用"
                ></el-switch>
              </el-form-item>
              <el-form-item label="权重">
                <el-input-number v-model="topicForm.weight" :min="0" :max="100"></el-input-number>
              </el-form-item>
            </el-form>
          </el-scrollbar>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="handleDialogClose">取 消</el-button>
              <el-button type="primary" @click="submitTopic">确 定</el-button>
            </span>
          </template>
        </el-dialog>

        <!-- 查看更多信息对话框 -->
        <el-dialog
          title="题目详细信息"
          v-model="moreInfoDialogVisible"
          width="60%"
        >
          <el-descriptions :column="2" border>
            <el-descriptions-item label="题目ID">{{ currentTopic.id }}</el-descriptions-item>
            <el-descriptions-item label="题目题">{{ currentTopic.title }}</el-descriptions-item>
            <el-descriptions-item label="题目类型">{{ getTopicTypeName(currentTopic.topic_type) }}</el-descriptions-item>
            <el-descriptions-item label="题目内容">{{ currentTopic.content }}</el-descriptions-item>
            <el-descriptions-item label="所属分类">
              <el-tag
                v-for="classify in currentTopic.classify_list"
                :key="classify.classify_id"
                class="mr-1 mb-1"
              >
                {{ classify.classify_name }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="分数范围">{{ currentTopic.score_range }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{ formatDate(currentTopic.created_at) }}</el-descriptions-item>
            <el-descriptions-item label="更新时间">{{ formatDate(currentTopic.updated_at) }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ currentTopic.status === 1 ? '启用' : '禁用' }}</el-descriptions-item>
            <el-descriptions-item label="权重">{{ currentTopic.weight }}</el-descriptions-item>
          </el-descriptions>
        </el-dialog>

        <!-- 查看选项对话框 -->
        <el-dialog
          title="题目选项"
          v-model="optionsDialogVisible"
          width="50%"
        >
          <el-table :data="currentOptions" style="width: 100%">
            <el-table-column prop="option" label="选项内容"></el-table-column>
            <el-table-column prop="option_type" label="选项类型">
              <template #default="scope">
                {{ getOptionTypeName(scope.row.option_type) }}
              </template>
            </el-table-column>
            <el-table-column prop="option_content" label="选项附加内容"></el-table-column>
          </el-table>
        </el-dialog>

        <!-- 查看所有分类对话框 -->
        <el-dialog
          title="所有分类"
          v-model="classifyDialogVisible"
          width="50%"
        >
          <el-tag
            v-for="classify in currentClassifies"
            :key="classify.id"
            class="mr-1 mb-1"
          >
            {{ classify.classify_name }}
          </el-tag>
        </el-dialog>

        <!-- 添加导出对话框 -->
        <el-dialog
          title="导出题目列表"
          v-model="exportDialogVisible"
          width="500px"
        >
          <el-form :model="exportForm" label-width="120px">
            <!-- 必填项放在最上面 -->
            <el-form-item label="导出文件类型" required>
              <el-select v-model="exportForm.file_type" placeholder="选择文件类型">
                <el-option
                  v-for="type in exportFileTypes"
                  :key="type.value"
                  :label="type.key"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
            
            <!-- 非必填项 -->
            <el-form-item label="所属项目">
              <div class="project-selection">
                <el-cascader
                  v-model="exportForm.classify_id"
                  :options="cascaderClassifyTree"
                  :props="{
                    checkStrictly: false,
                    emitPath: false,
                  }"
                  clearable
                  placeholder="请选择所属项目（可选）"
                ></el-cascader>
              </div>
            </el-form-item>
            
            <el-form-item label="题目类型">
              <el-select v-model="exportForm.topic_type" placeholder="选择题目类型（可选）" clearable>
                <el-option
                  v-for="type in topicTypes"
                  :key="type.value"
                  :label="type.key"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="exportDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleExport" :loading="exporting">确 定</el-button>
            </span>
          </template>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch, nextTick } from 'vue'
import { Document, Folder, FolderOpened, Collection, User, Timer, Reading, Edit, Select, Switch, Rank, EditPen, ArrowDown, Delete } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del, downloadFile } from '@/utils/request'
import env from '../../.env.js'; // 引入环境变量
import FileUpload from '@/components/FileUpload.vue'
import { getConstantByKey, NODE_TYPES, FILE_BUSINESS_TYPES, EXPORT_FILE_TYPES } from '@/utils/constants'

// 添加文件类型和业务类型常量
const FILE_TYPE = {
  UNKNOWN: 0,
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  DOCUMENT: 4
}

export default {
  name: 'TopicManagement',
  components: {
    Document,
    Folder,
    FolderOpened,
    Collection,
    User,
    Timer,
    Reading,
    FileUpload, // 添加 FileUpload 组件到 components 中
    Edit,
    Select,
    Switch,
    Rank,
    EditPen,
    ArrowDown,
    Delete
  },
  setup() {
    const apiBaseUrl = env.BASE_URL; // 获取基础URL

    // 如果需要添加认证Token等，可以在 headers 中添加
      // 从缓存中获取token
  const userInfoString = localStorage.getItem('userInfo');
  // 将JSON字符串转换为JSON对象
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  // 如果有token，则添加到header中
  const uploadHeaders = {}
  if (userInfo && userInfo.token) {
    uploadHeaders.Authorization = userInfo.token;
  }

    const searchForm = reactive({
      title: '',
      topic_type: '',
      classify_id: '',
      status: ''
    })
    const topics = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增题目')
    const topicForm = reactive({
      id: null,
      title: '',
      topic_type: '',
      content: '',
      options: [],
      answer: {},
      score_range: [0, 3000],
      video: '',
      audio: '',
      file: '',
      status: 1,
      weight: 0,
      currentTag: '',
      tagList: [],
      classify_id: '',
      pics: [], // 存储图片的 object_key 数组
      pictures: [], // 存储图片 URL 数组，用于显示
    })

    const topicTypes = ref([])
    const optionTypes = ref([])
    const classifyTree = ref([])
    const cascaderClassifyTree = ref([])
    const classifyMap = ref({})
    const classifyMapReady = ref(false)
    const selectedClassifies = ref([])

    const picFileList = ref([])
    const videoFileList = ref([])
    const audioFileList = ref([])
    const fileFileList = ref([])

    const treeRef = ref(null)
    const currentPath = ref([])
    const defaultProps = {
      children: 'children',
      label: 'name',
      hasChildren: true
    }

    // 添加加载子节点的方法
    const loadNode = async (node, resolve) => {
      if (node.level === 0) {
        resolve(classifyTree.value)
        return
      }

      try {
        const parentId = node.data.id || node.data.base_info.id
        const res = await get(`/auth/admin/query/classify_nodes_by_parent/${parentId}`)
        if (res.data && res.data.nodes) {
          resolve(res.data.nodes)
        } else {
          resolve([])
        }
      } catch (error) {
        console.error('加载子节点失败:', error)
        ElMessage.error('加载子节点失败')
        resolve([])
      }
    }

    // 修改节点点击处理方法
    const handleNodeClick = async (data, node) => {
      // 清除之前的选中状态
      if (treeRef.value) {
        treeRef.value.setCurrentKey(data.id)
      }

      if (!node.expanded) {
        node.expanded = true
        if (!node.isLeaf) {
          try {
            const parentId = node.data.id || node.data.base_info.id
            const res = await get(`/auth/admin/query/classify_nodes_by_parent/${parentId}`)
            if (res.data && res.data.nodes) {
              node.childNodes = []
              if (node.data.children) {
                node.data.children.length = 0
              }
              
              res.data.nodes.forEach(childNode => {
                treeRef.value.append(childNode, node)
              })
            }
          } catch (error) {
            console.error('加载子节点失败:', error)
            ElMessage.error('加载子节点失败')
          }
        }
      } else {
        node.expanded = false
      }

      // 更新当前路径
      currentPath.value = []
      let currentNode = node
      while (currentNode.parent && currentNode.parent.level >= 0) {
        currentPath.value.unshift({
          id: currentNode.data.id,
          name: currentNode.data.name
        })
        currentNode = currentNode.parent
      }

      // 更新搜索条件并执行搜索
      searchForm.classify_id = data.id || data.base_info.id
      await searchTopics()

      // 如果是试卷节点，设置为默认选中
      if (data.node_type === NODE_TYPES.PAPER) {
        console.log("当前节点为试卷",data)
        selectedClassify.value = data.base_info.id
      }
    }

    // 处理面包屑点击
    const handleBreadcrumbClick = async (item) => {
      try {
        // 在树中找到并定位到对应节点
        const node = treeRef.value?.getNode(item.id)
        if (node) {
          // 展开到当前节点的路径
          let currentNode = node
          while (currentNode.parent && currentNode.parent.level >= 0) {
            currentNode.parent.expanded = true
            currentNode = currentNode.parent
          }
          
          // 设置当前选中节点
          treeRef.value?.setCurrentKey(item.id)
          
          // 使用 nextTick 确保 DOM 已更新
          await nextTick()
          
          // 滚动到选中节点
          const nodeElement = document.querySelector(`.el-tree-node[data-key="${item.id}"]`)
          if (nodeElement) {
            nodeElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
          
          // 触发节点点击事件
          handleNodeClick(node.data, node)
        }
      } catch (error) {
        console.error('面包屑导航错误:', error)
      }
    }

    const searchTopics = async () => {
      try {
        const params = {
          page: currentPage.value,
          size: pageSize.value,
          title: searchForm.title || '',
          topic_type: searchForm.topic_type || 0,
          classify_id: searchForm.classify_id || '',
          status: searchForm.status || 0
        }
        
        const res = await get('/auth/admin/teacher/topics', params)
        
        if (res.data && res.data.topics) {
          topics.value = res.data.topics
            .filter(topic => topic.base_info.id) // 过滤掉空数据
            .map(topic => ({
              id: topic.base_info.id,
              title: topic.title,
              topic_type: topic.topic_type,
              content: topic.content,
              classify_id: topic.classify_id,
              option_list: topic.option_list || [],
              answer: topic.answer,
              score_range: topic.score_range,
              pics: topic.pics,
              video: topic.video,
              audio: topic.audio,
              file: topic.file,
              status: topic.status,
              weight: topic.weight,
              created_at: topic.base_info.created_at,
              updated_at: topic.base_info.updated_at
            }))
          total.value = res.data.total
        }
      } catch (error) {
        console.error('获取题目列表失败:', error)
        ElMessage.error('获取题目列表失败')
        topics.value = []
        total.value = 0
      }
    }

    const resetSearch = () => {
      Object.keys(searchForm).forEach(key => {
        searchForm[key] = ''
      })
      currentPage.value = 1
      pageSize.value = 10
      currentPath.value = [] // 清空当前路径
      if (treeRef.value) {
        treeRef.value.setCurrentKey(null) // 清除树的选中状态
      }
      searchTopics()
    }

    const openCreateDialog = () => {
      dialogTitle.value = '新增题目'
      
      // 重置所有表单字段
      Object.assign(topicForm, {
        id: null,
        title: '',
        topic_type: '',
        content: '',
        options: [],
        answer: {},
        score_range: [0, 3000],
        video: '',
        audio: '',
        file: '',
        status: 1,
        weight: 0,
        currentTag: '',
        tagList: [],
        classify_id: selectedClassify.value || '',
        pics: [], // 清空图片 object_key 数组
        pictures: [], // 清空图片 URL 数组
      })

      // 重置所有文件列表为空数组
      picFileList.value = []
      videoFileList.value = []
      audioFileList.value = []
      fileFileList.value = []

      // 重置批量标签输入
      batchTags.value = ''

      // 重置折叠面板状态
      activeCollapse.value = []
      resetAllUploads()

      dialogVisible.value = true
    }

    const editTopic = async (row) => {
      if (!row) return
      dialogTitle.value = '编辑题目'
      
      // 确保 classifyMap 已经准备好
      if (!classifyMapReady.value) {
        await getClassifyTree()
      }

      // 设置表单数据
      Object.assign(topicForm, {
        id: row.id,
        title: row.title,
        topic_type: row.topic_type,
        content: row.content,
        classify_id: row.classify_id || '',
        options: row.option_list ? row.option_list.map(option => ({
          option: option.option,
          option_type: option.option_type,
          option_content: option.option_content || '',
          score: option.score || 0
        })) : [],
        answer: row.answer || {},
        score_range: row.score_range ? 
          row.score_range.split('-').map(Number) : 
          [0, 3000],
        status: row.status,
        weight: row.weight,
        currentTag: '',
        tagList: row.tags ? row.tags.split(',').filter(tag => tag) : [],
        // 修改附件相关字段的处理
        pics: Array.isArray(row.pics) ? row.pics : [], // 存储图片的 object_key
        pictures: Array.isArray(row.pics) ? row.pics : [], // 用于显示的图片URL数组
        video: row.video || '',
        audio: row.audio || '',
        file: row.file || ''
      })

      // 处理图片回显
      if (Array.isArray(row.pics) && row.pics.length > 0) {
        topicForm.pics = [...row.pics] // 存储 object_key
        topicForm.pictures = [...row.pics] // 存储 URL
        
        picFileList.value = row.pics.map((url, index) => ({
          name: `图片${index + 1}`,
          url: url,
          status: 'success',
          uid: index,
          object_key: row.pics[index] // 保存 object_key 信息
        }))
      }

      // 处理视频回显
      if (row.video) {
        videoFileList.value = [{
          name: '当前视频',
          url: row.video,
          status: 'success',
          uid: 0,
          object_key: row.video
        }]
      } else {
        videoFileList.value = []
      }

      // 处理音频回显
      if (row.audio) {
        audioFileList.value = [{
          name: '当前音频',
          url: row.audio,
          status: 'success',
          uid: 0,
          object_key: row.audio
        }]
      } else {
        audioFileList.value = []
      }

      // 处理文件回显
      if (row.file) {
        fileFileList.value = [{
          name: '当前文件',
          url: row.file,
          status: 'success',
          uid: 0,
          object_key: row.file
        }]
      } else {
        fileFileList.value = []
      }

      // 设置级联选择器的值
      selectedClassify.value = row.classify_id || null

      // 如果有分类ID，确保在树形控件中显示正确的路径
      if (topicForm.classify_id && treeRef.value) {
        const node = treeRef.value.getNode(topicForm.classify_id)
        if (node) {
          // 展开到当前节点
          let currentNode = node
          while (currentNode.parent && currentNode.parent.level >= 0) {
            currentNode.parent.expanded = true
            currentNode = currentNode.parent
          }
          // 设置当前选中节点
          treeRef.value.setCurrentKey(topicForm.classify_id)

          // 更新面包屑导航
          currentPath.value = []
          let tempNode = node
          while (tempNode.parent && tempNode.parent.level >= 0) {
            currentPath.value.unshift({
              id: tempNode.data.id,
              name: tempNode.data.name
            })
            tempNode = tempNode.parent
          }
        }
      }

      dialogVisible.value = true
    }

    const deleteTopic = (row) => {
      ElMessageBox.confirm('确定要删除这个题目吗？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await del(`/auth/admin/teacher/topic/${row.id}`)
          ElMessage.success('删除成功')
          searchTopics()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => {})
    }

    const getClassifyTree = async () => {
      try {
        const res = await get('/auth/admin/query/classify_tree') // 假设 0 是根节点ID
        if (res.data && res.data.nodes) {
          cascaderClassifyTree.value = transformClassifyTree(res.data.nodes)
          updateClassifyMap(cascaderClassifyTree.value)
          classifyTree.value = transformClassifyTree(res.data.nodes)
          updateClassifyMap(classifyTree.value)
          classifyMapReady.value = true
        }
      } catch (error) {
        console.error('获取项目树失败:', error)
        ElMessage.error('获取项目树失败')
      }
    }

    const transformClassifyTree = (nodes) => {
      if (!nodes) return [] // 添加空值检查
      return nodes.map(item => ({
        id: item.base_info.id,
        value: item.base_info.id,
        name: item.name,
        label: item.name,
        node_type: item.node_type,
        children: item.children ? transformClassifyTree(item.children) : null,
        rawData: item
      }))
    }

    const updateClassifyMap = (nodes) => {
      nodes.forEach(node => {
        // 使用 id 作为键，存储完整的节点信息
        classifyMap.value[node.id] = {
          name: node.name,
          node_type: node.node_type,
          rawData: node.rawData
        }
        if (node.children && node.children.length > 0) {
          updateClassifyMap(node.children)
        }
      })
    }

    const getClassifyName = (classifyId) => {
      const classifyInfo = classifyMap.value[classifyId]
      return classifyInfo ? classifyInfo.name : '未知分类'
    }

    const handleTopicTypeChange = () => {
      topicForm.options = []
      topicForm.answer = {}
      topicForm.score_range = [0, 3000]
      
      // 如果是判断题，自动添加"是"、"否"和"偶尔"选项
      if (topicForm.topic_type === getTopicTypeValue('判断题')) {
        topicForm.options = [
          {
            option: '是',
            option_type: getOptionTypeValue('正确'),
            option_content: '',
            score: 0
          },
          {
            option: '否',
            option_type: getOptionTypeValue('错误'),
            option_content: '',
            score: 0
          },
          {
            option: '偶尔',
            option_type: getOptionTypeValue('偶尔'), // 假设选项类型中有"部分正确"类型
            option_content: '',
            score: 0
          }
        ]
      }
    }

    const addOption = () => {
      topicForm.options.push({
        option: '',
        option_type: 1,
        option_content: '',
        score: 0
      })
    }

    const removeOption = (index) => {
      topicForm.options.splice(index, 1)
    }

    const handleClassifyChange = (value) => {
      if (!value) {
        topicForm.classify_id = ''
        selectedClassify.value = null
        return
      }
      console.log("当前级联选择器值",value)

      const classifyInfo = classifyMap.value[value]
      if (!classifyInfo) {
        ElMessage.warning('无法找到选中的分类信息')
        selectedClassify.value = null
        topicForm.classify_id = ''
        return
      }

      if (classifyInfo.node_type !== NODE_TYPES.PAPER) {
        ElMessage.warning('请选择试卷类型的节点')
        selectedClassify.value = null
        topicForm.classify_id = ''
        return
      }

      topicForm.classify_id = value
      selectedClassify.value = value
    }

    const removeClassify = (classifyId) => {
      const index = topicForm.classify_id_list.findIndex(item => item.classify_id === classifyId)
      if (index > -1) {
        topicForm.classify_id_list.splice(index, 1)
        // 同时更新 selectedClassifies
        const selectedIndex = selectedClassifies.value.indexOf(classifyId)
        if (selectedIndex > -1) {
          selectedClassifies.value.splice(selectedIndex, 1)
        }
      }
    }

    // 修改处理图片上传成功的方法
    const handlePictureUploadSuccess = (fileInfo, file, fileList) => {
      console.log("图片上传成功", fileInfo, file, fileList)
      
      // 更新 topicForm 中的数组
      topicForm.pictures = fileList.map(f => f.url)
      topicForm.pics = fileList.map(f => f.object_key)
      console.log("topicForm.pictures", topicForm.pictures)
      console.log("topicForm.pics", topicForm.pics)
    }

    // 修改处理图片移除的方法
    const handlePictureRemove = (file, remainingFiles) => {
      console.log("图片移除", file, remainingFiles)
      
      // 直接使用新的文件列表更新数组
      topicForm.pictures = remainingFiles.map(f => f.url)
      topicForm.pics = remainingFiles.map(f => f.object_key)
    }

    // 修改处理图片排序的方法
    const handlePictureSort = (sortedFiles) => {
      console.log("图片排序", sortedFiles)
      
      // 使用排序后的文件列表更新数组
      topicForm.pictures = sortedFiles.map(f => f.url)
      topicForm.pics = sortedFiles.map(f => f.object_key)
    }

    // 修改处理视频上传成功的方法
    const handleVideoUploadSuccess = (fileInfo) => {
      console.log("视频上传成功", fileInfo)
      topicForm.video = fileInfo.object_key  // 存储 object_key 用于提交
      videoFileList.value = [{               // 更新预览用的文件列表
        name: fileInfo.name,
        url: fileInfo.url,                   // 使用 url 用于预览
        status: 'success',
        uid: fileInfo.uid,
        object_key: fileInfo.object_key
      }]
      ElMessage.success('视频上传成功')
    }

    const handleAudioUploadSuccess = (fileInfo) => {
      topicForm.audio = fileInfo.object_key  // 存储 object_key 用于提交
      audioFileList.value = [{               // 更新预览用的文件列表
        name: fileInfo.name,
        url: fileInfo.url,                   // 使用 url 用于预览
        status: 'success',
        uid: fileInfo.uid,
        object_key: fileInfo.object_key
      }]
      ElMessage.success('音频上传成功')
    }

    const handleFileUploadSuccess = (fileInfo) => {
      topicForm.file = fileInfo.object_key   // 存储 object_key 用于提交
      fileFileList.value = [{                // 更新预览用的文件列表
        name: fileInfo.name,
        url: fileInfo.url,                   // 使用 url 用于预览
        status: 'success',
        uid: fileInfo.uid,
        object_key: fileInfo.object_key
      }]
      ElMessage.success('文件上传成功')
    }

    const beforeUpload = (file) => {
      console.log(file)
      // 在这里可以添加文件类型和大小的验证
      return true
    }

    const updateScoreRange = (value) => {
      if (Array.isArray(value) && value.length === 2) {
        topicForm.score_range = value // 确保 scoreRange 始终是一个数组
      }
    }

    // 修改提交方法
    const submitTopic = async () => {
      // 验证必填项
      if (!topicForm.title.trim()) {
        ElMessage.error('请输入题目标题')
        return
      }
      if (!topicForm.topic_type) {
        ElMessage.error('请选择题目类型')
        return
      }
      if (!topicForm.classify_id) {
        ElMessage.error('请选择所属项目')
        return
      }
      
      // 验证选项(对于选择题和判断题)
      if ([getTopicTypeValue('单选题'), getTopicTypeValue('多选题')].includes(topicForm.topic_type)) {
        if (!topicForm.options || topicForm.options.length === 0) {
          ElMessage.error('请添加题目选项')
          return
        }
        
        // 验证每个选项
        for (let i = 0; i < topicForm.options.length; i++) {
          const option = topicForm.options[i]
          if (!option.option.trim()) {
            ElMessage.error(`请输入第${i + 1}个选项的内容`)
            return
          }
          if (!option.option_type) {
            ElMessage.error(`请选择第${i + 1}个选项的类型`)
            return
          }
        }
      }

      // 构建基础提交数据
      const topicData = {
        title: topicForm.title,
        topic_type: topicForm.topic_type,
        content: topicForm.content,
        classify_id: topicForm.classify_id,
        options: topicForm.options.map(opt => ({
          option: opt.option,
          option_type: opt.option_type,
          option_content: opt.option_content || '',
          score: opt.score || 0
        })),
        answer: topicForm.answer,
        score_range: Array.isArray(topicForm.score_range) ? 
          `${topicForm.score_range[0]}-${topicForm.score_range[1]}` : 
          topicForm.score_range,
        // 确保所有附件字段都被正确提交
        pics: topicForm.pics.filter(Boolean), // 过滤掉空值
        video: topicForm.video || '',
        audio: topicForm.audio || '',
        file: topicForm.file || '',
        status: topicForm.status,
        weight: topicForm.weight,
        tags: topicForm.tagList.join(',')
      }

      try {
        if (topicForm.id) {
          // 更新题目
          const updateData = {
            topic: topicData
          }
          await put(`/auth/admin/teacher/topic/${topicForm.id}`, updateData)
          ElMessage.success('更新成功')
        } else {
          // 创建题目
          await post('/auth/admin/teacher/topic', topicData)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchTopics()
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1 // 重置到第一页
      searchTopics()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchTopics()
    }

    const handleDialogClose = (done) => {
      ElMessageBox.confirm('确定要关闭吗？未保存的内容将会丢失。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        resetAllUploads()
        dialogVisible.value = false
        if (done) done()
      }).catch(() => {})
    }

    const getTopicTypeName = (value) => {
      const typesData = getConstantByKey('topicTypes')
      if (!typesData || !typesData.types) return '未知类型'
      
      const types = typesData.types
      const type = types.find(t => t.value === value)
      return type ? type.key : '未知类型'
    }

    onMounted(async () => {
      // 获取目类型常量
      const topicTypesData = getConstantByKey('topicTypes')
      console.log('题目类型常量:', topicTypesData) // 添加日志
      
      if (topicTypesData && topicTypesData.types) {
        topicTypes.value = topicTypesData.types
      } else {
        console.error('题目类型数据格式错误')
        topicTypes.value = []
      }

      // 获取选项类型常量
      const optionTypesData = getConstantByKey('topicOptionTypes')
      console.log('选项类型常量:', optionTypesData) // 添加日志
      
      if (optionTypesData && optionTypesData.types) {
        optionTypes.value = optionTypesData.types
      } else {
        console.error('选项类型数据格式错误')
        optionTypes.value = []
      }

      await getClassifyTree() // 获取分类树
      await searchTopics()
    })

    // 监听 classifyTree 的变化
    watch(classifyTree, () => {
      updateClassifyMap(classifyTree.value)
      classifyMapReady.value = true
    })

    const classifyDialogVisible = ref(false)
    const optionsDialogVisible = ref(false)
    const moreInfoDialogVisible = ref(false)
    const currentClassifies = ref([])
    const currentOptions = ref([])
    const currentTopic = ref({})

    const showAllClassifies = (classifies) => {
      currentClassifies.value = classifies
      classifyDialogVisible.value = true
    }

    const showOptions = (options) => {
      currentOptions.value = options
      optionsDialogVisible.value = true
    }

    const showMoreInfo = (topic) => {
      currentTopic.value = topic
      moreInfoDialogVisible.value = true
    }

    const getOptionTypeName = (value) => {
      const type = optionTypes.value.find(t => t.value === value)
      return type ? type.key : '未知类型'
    }

    const formatDate = (timestamp) => {
      if (!timestamp) return '未知'
      const date = new Date(timestamp * 1000)
      return date.toLocaleString()
    }

    const getTopicTypeValue = (typeName) => {
      const typesData = getConstantByKey('topicTypes')
      if (!typesData || !typesData.types) return null

      const types = typesData.types
      const type = types.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    const getOptionTypeValue = (typeName) => {
      const typesData = getConstantByKey('topicOptionTypes')
      if (!typesData || !typesData.types) return null
        
      const types = typesData.types
      const type = types.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    const fileTypes = ref(getConstantByKey('fileTypes') || [])

    const getFileTypeValue = (typeName) => {
      const type = fileTypes.value.find(t => t.key === typeName);
      return type ? type.value : null;
    }

    // 在 setup() 中添加新的响应式数据
    const activeCollapse = ref([])
    const selectedClassify = ref(null)
    const batchTags = ref('')

    // 标签相关方法
    const addTag = () => {
      if (!topicForm.currentTag.trim()) return
      
      if (!topicForm.tagList.includes(topicForm.currentTag.trim())) {
        topicForm.tagList.push(topicForm.currentTag.trim())
        batchTags.value = topicForm.tagList.join(',')
      }
      topicForm.currentTag = ''
    }

    const removeTag = (tag) => {
      topicForm.tagList = topicForm.tagList.filter(t => t !== tag)
      batchTags.value = topicForm.tagList.join(',')
    }

    const handleBatchTags = (value) => {
      if (!value) {
        topicForm.tagList = []
        return
      }
      const tags = value.split(',')
        .map(tag => tag.trim())
        .filter(tag => tag)
      
      topicForm.tagList = [...new Set(tags)]
      batchTags.value = topicForm.tagList.join(',')
    }

    const handleCascaderLazyLoad = async (node, resolve) => {
      // 根节点
      if (node.level === 0) {
        resolve(classifyTree.value)
        return
      }

      try {
        const parentId = node.data.id || (node.data.base_info && node.data.base_info.id)
        const res = await get(`/auth/admin/query/classify_nodes_by_parent/${parentId}`)
        if (res.data && res.data.nodes) {
          const nodes = res.data.nodes.map(item => {
            const transformedNode = {
              id: item.base_info.id,
              name: item.name,
              value: item.base_info.id,
              label: item.name,
              node_type: item.node_type,
              leaf: item.node_type === NODE_TYPES.PAPER,
              rawData: item
            }
            // 更新分类映射
            classifyMap.value[transformedNode.id] = {
              name: item.name,
              node_type: item.node_type,
              rawData: item
            }
            return transformedNode
          })
          resolve(nodes)
        } else {
          resolve([])
        }
      } catch (error) {
        console.error('加载子节点失败:', error)
        ElMessage.error('加载子节点失败')
        resolve([])
      }
    }

    // 添加 watch 来监听 topicForm.classify_id 的变化
    watch(() => topicForm.classify_id, (newVal) => {
      if (newVal && newVal !== selectedClassify.value) {
        selectedClassify.value = newVal
        console.log("当前级联选择器值",selectedClassify.value)
      }
    })

    const getNodeIconClass = (nodeType) => {
      const baseClass = 'node-icon'
      switch (nodeType) {
        case NODE_TYPES.ROOT:
          return `${baseClass} root-icon`
        case NODE_TYPES.PAPER:
          return `${baseClass} paper-icon`
        case NODE_TYPES.PROJECT:
          return `${baseClass} project-icon`
        case NODE_TYPES.MODULE:
          return `${baseClass} module-icon`
        case NODE_TYPES.TEACHER:
          return `${baseClass} teacher-icon`
        case NODE_TYPES.AGE:
          return `${baseClass} age-icon`
        case NODE_TYPES.MAJOR:
          return `${baseClass} major-icon`
        default:
          return baseClass
      }
    }

    const getNodeTextClass = (nodeType) => {
      const baseClass = 'node-text'
      switch (nodeType) {
        case NODE_TYPES.ROOT:
          return `${baseClass} root-text`
        case NODE_TYPES.PAPER:
          return `${baseClass} paper-text`
        case NODE_TYPES.PROJECT:
          return `${baseClass} project-text`
        case NODE_TYPES.MODULE:
          return `${baseClass} module-text`
        case NODE_TYPES.TEACHER:
          return `${baseClass} teacher-text`
        case NODE_TYPES.AGE:
          return `${baseClass} age-text`
        case NODE_TYPES.MAJOR:
          return `${baseClass} major-text`
        default:
          return baseClass
      }
    }

    const getNodeTypeName = (nodeType) => {
      switch (nodeType) {
        case NODE_TYPES.ROOT:
          return '根节点'
        case NODE_TYPES.PAPER:
          return '试卷'
        case NODE_TYPES.PROJECT:
          return '项目'
        case NODE_TYPES.MODULE:
          return '模块'
        case NODE_TYPES.TEACHER:
          return '老师'
        case NODE_TYPES.AGE:
          return '年龄段'
        case NODE_TYPES.MAJOR:
          return '专业'
        default:
          return '未知'
      }
    }

    const getNodeTypeTagClass = (nodeType) => {
      switch (nodeType) {
        case NODE_TYPES.ROOT:
          return 'warning'
        case NODE_TYPES.PAPER:
          return 'paper-tag'
        case NODE_TYPES.PROJECT:
          return 'project-tag'
        case NODE_TYPES.MODULE:
          return 'module-tag'
        case NODE_TYPES.TEACHER:
          return 'teacher-tag'
        case NODE_TYPES.AGE:
          return 'age-tag'
        case NODE_TYPES.MAJOR:
          return 'major-tag'
        default:
          return ''
      }
    }

    const getTopicTypeIconClass = (topicType) => {
      const baseClass = 'topic-type-icon'
      switch (topicType) {
        case getTopicTypeValue('单选题'):
          return `${baseClass} single-choice-icon`
        case getTopicTypeValue('多选题'):
          return `${baseClass} multiple-choice-icon`
        case getTopicTypeValue('判断题'):
          return `${baseClass} judge-icon`
        case getTopicTypeValue('打分制题'):
          return `${baseClass} score-icon`
        case getTopicTypeValue('问答题'):
          return `${baseClass} essay-icon`
        default:
          return baseClass
      }
    }

    const getTopicTypeTextClass = (topicType) => {
      const baseClass = 'topic-type-text'
      switch (topicType) {
        case getTopicTypeValue('单选题'):
          return `${baseClass} single-choice-text`
        case getTopicTypeValue('多选题'):
          return `${baseClass} multiple-choice-text`
        case getTopicTypeValue('判断题'):
          return `${baseClass} judge-text`
        case getTopicTypeValue('打分制题'):
          return `${baseClass} score-text`
        case getTopicTypeValue('问答题'):
          return `${baseClass} essay-text`
        default:
          return baseClass
      }
    }

    const handleAudioRemove = () => {
      topicForm.audio = ''
    }

    const handleFileRemove = () => {
      topicForm.file = ''
    }

    const handleVideoRemove = () => {
      topicForm.video = ''
    }

    // 计算附件数量的方法
    const getAttachmentCount = () => {
      let count = 0
      if (topicForm.pictures && topicForm.pictures.length) count += topicForm.pictures.length
      if (topicForm.video) count++
      if (topicForm.audio) count++
      if (topicForm.file) count++
      return count
    }

    // 在 setup 中定义 ref
    const imageUploadRef = ref(null)
    const videoUploadRef = ref(null)
    const audioUploadRef = ref(null)
    const fileUploadRef = ref(null)

    // 修改重置方法
    const resetAllUploads = () => {
      imageUploadRef.value?.resetFiles()
      videoUploadRef.value?.resetFiles()
      audioUploadRef.value?.resetFiles()
      fileUploadRef.value?.resetFiles()
    }

    // 添加导出相关的响应式数据
    const exportDialogVisible = ref(false)
    const exporting = ref(false)
    const exportFileTypes = ref(EXPORT_FILE_TYPES)
    const exportForm = reactive({
      classify_id: '',
      topic_type: '',
      file_type: 'excel', // 默认导出为Excel
      file_name: ''
    })

    // 打开导出对话框
    const openExportDialog = () => {
      exportForm.classify_id = searchForm.classify_id // 默认使用当前选中的项目
      exportForm.topic_type = ''
      exportForm.file_name = ''
      exportDialogVisible.value = true
    }

    // 处理导出
    const handleExport = async () => {
      if (!exportForm.file_type) {
        ElMessage.warning('请选择导出文件类型')
        return
      }

      try {
        exporting.value = true
        
        // 构建请求参数
        const params = {
          classify_id: exportForm.classify_id || '',
          topic_type: exportForm.topic_type || '',
          file_type: exportForm.file_type,
          file_name: exportForm.file_name || ''
        }

        // 使用封装的下载方法
        const success = await downloadFile('/auth/admin/teacher/export_topics', params)
        
        if (success) {
          ElMessage.success('导出成功')
          exportDialogVisible.value = false
        }
      } catch (error) {
        console.error('导出失败:', error)
        ElMessage.error('导出失败')
      } finally {
        exporting.value = false
      }
    }

    return {
      apiBaseUrl, // 导出基础URL
      uploadHeaders, // 导出上传头部
      searchForm,
      topics,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      dialogTitle,
      topicForm,
      topicTypes,
      optionTypes,
      classifyTree,
      cascaderClassifyTree,
      selectedClassifies,
      searchTopics,
      resetSearch,
      openCreateDialog,
      editTopic,
      deleteTopic,
      handleTopicTypeChange,
      addOption,
      removeOption,
      handleClassifyChange,
      removeClassify,
      getClassifyName,
      handlePictureUploadSuccess,
      handleVideoUploadSuccess,
      handleAudioUploadSuccess,
      handleFileUploadSuccess,
      beforeUpload,
      updateScoreRange,
      submitTopic,
      handleSizeChange,
      handleCurrentChange,
      handleDialogClose,
      getTopicTypeName,
      classifyDialogVisible,
      optionsDialogVisible,
      moreInfoDialogVisible,
      currentClassifies,
      currentOptions,
      currentTopic,
      showAllClassifies,
      showOptions,
      showMoreInfo,
      getOptionTypeName,
      formatDate,
      getTopicTypeValue,
      getOptionTypeValue,
      picFileList,
      videoFileList,
      audioFileList,
      fileFileList,
      fileTypes,
      getFileTypeValue,
      treeRef,
      currentPath,
      defaultProps,
      handleNodeClick,
      handleBreadcrumbClick,
      activeCollapse,
      selectedClassify,
      batchTags,
      addTag,
      removeTag,
      handleBatchTags,
      NODE_TYPES,
      loadNode,
      handleCascaderLazyLoad,
      getNodeIconClass,
      getNodeTextClass,
      getNodeTypeName,
      getNodeTypeTagClass,
      getTopicTypeIconClass,
      getTopicTypeTextClass,
      FILE_TYPE,
      FILE_BUSINESS_TYPES,
      handlePictureRemove,
      handlePictureSort,
      handleAudioRemove,
      handleFileRemove,
      handleVideoRemove,
      getAttachmentCount,
      imageUploadRef,
      videoUploadRef,
      audioUploadRef,
      fileUploadRef,
      resetAllUploads,
      exportDialogVisible,
      exporting,
      exportFileTypes,
      exportForm,
      openExportDialog,
      handleExport
    }
  }
}
</script>

<style scoped>
.topic-management {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--el-bg-color);
}

.topic-management :deep(.el-container) {
  height: 100%;
  flex: 1;
  min-height: 0; /* 关键：防止 flex 子项超出容器 */
}

.topic-main {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  min-height: 0; /* 关键：防止 flex 子项超出容器 */
}

.breadcrumb {
  margin-bottom: 16px;
  flex-shrink: 0;
}

.search-card {
  margin-bottom: 16px;
  flex-shrink: 0;
}

.table-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* 关键：防止 flex 子项超出容器 */
  margin-bottom: 16px;
}

.table-card :deep(.el-card__body) {
  flex: 1;
  overflow: auto;
  min-height: 0; /* 关键：防止 flex 子项超出容器 */
}

.pagination-container {
  margin-top: auto;
  padding: 16px 0;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
}

.tree-aside {
  border-right: 1px solid var(--el-border-color-light);
  background-color: var(--el-bg-color);
  transition: width 0.3s;
  width: 360px;
  overflow: hidden;
  height: 100%;
}

.el-main {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.topic-list-card {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.pagination-container {
  margin-top: auto;
  padding: 1rem 0;
}

.el-card.mb-4 {
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.el-breadcrumb {
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.tree-card {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}

:deep(.el-scrollbar) {
  height: 100%;
}

:deep(.el-scrollbar__wrap) {
  overflow-x: hidden !important;
}

.custom-tree {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.custom-tree :deep(.el-tree-node) {
  margin: 4px 0;
}

.custom-tree :deep(.el-tree-node__content) {
  height: 40px;
  border-radius: 4px;
  transition: all 0.3s;
  width: 100%;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.custom-tree :deep(.el-tree-node__content:hover) {
  background-color: var(--el-color-primary-light-9);
}

.custom-tree :deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: var(--el-color-primary-light-8);
  color: var(--el-color-primary);
}

.custom-tree-node {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
}

.node-icon {
  font-size: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 不同节点类型的图标颜色 */
.paper-icon {
  color: #409EFF; /* 蓝色 */
}

.project-icon {
  color: #67C23A; /* 绿色 */
}

.module-icon {
  color: #E6A23C; /* 橙色 */
}

.teacher-icon {
  color: #F56C6C; /* 红色 */
}

.age-icon {
  color: #909399; /* 灰色 */
}

.major-icon {
  color: #9C27B0; /* 紫色 */
}

/* 统一文字样式 */
.node-text {
  font-size: 15px;
  font-weight: 600;
  flex: 1;
  color: var(--el-text-color-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 节点类型标签样式 */
.node-type-tag {
  font-size: 12px;
  padding: 2px 6px;
  background-color: var(--el-color-info-light-9);
  color: var(--el-text-color-secondary);
  border-radius: 4px;
  margin-right: 8px;
}

/* 确保树节点内容左对齐 */
:deep(.el-tree-node__content) {
  justify-content: flex-start;
}

/* 选中节点的高亮效果 */
:deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: var(--el-color-primary-light-9);
}

.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.topic-list-card {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}
.pagination-container {
  margin-top: auto;
  padding: 1rem 0;
}
.el-divider {
  background-color: #f0f2f5;
  margin: 24px 0;
  border-radius: 4px;
}
.el-divider__text {
  background-color: #fff;
  padding: 0 20px;
  font-weight: bold;
  color: #606266;
}
:deep(.el-dialog__body) {
  padding: 0;
}

:deep(.el-scrollbar__view) {
  padding: 20px;
}

.option-row {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.option-row .el-input {
  flex-grow: 1;
}

.option-row .el-select {
  width: 120px;
}

.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}

.project-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.selected-tags {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.el-cascader {
  width: 100%;
}

.project-selection {
  margin-bottom: 10px;
}

.selected-classify {
  margin-top: 8px;
  color: #666;
}

.tag-input-area {
  margin-bottom: 15px;
}

.single-tag-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.tag-display {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.option-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.project-selection {
  margin-bottom: 10px;
}

.selected-classify {
  margin-top: 8px;
  color: #666;
}

.tag-input-area {
  margin-bottom: 15px;
}

.single-tag-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.tag-display {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.option-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.topic-type-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;
}

.topic-type-icon {
  font-size: 16px;
}

/* 单选题样式 */
.single-choice-icon {
  color: #409EFF;
}
.single-choice-text {
  color: #409EFF;
}

/* 多选题样式 */
.multiple-choice-icon {
  color: #67C23A;
}
.multiple-choice-text {
  color: #67C23A;
}

/* 判断题样式 */
.judge-icon {
  color: #E6A23C;
}
.judge-text {
  color: #E6A23C;
}

/* 打分制题样式 */
.score-icon {
  color: #F56C6C;
}
.score-text {
  color: #F56C6C;
}

/* 问答题样式 */
.essay-icon {
  color: #909399;
}
.essay-text {
  color: #909399;
}

.topic-type-text {
  font-weight: 500;
}

.preview-area {
  margin-top: 16px;
  padding: 8px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.default-preview {
  padding: 8px;
  text-align: center;
}

/* 附件折叠面板样式 */
.attachment-collapse {
  --el-collapse-header-height: 56px;
}

.attachment-header {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
}

.expand-hint {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  padding: 6px 12px;
  border-radius: 4px;
  background: linear-gradient(45deg, var(--el-color-warning) 0%, var(--el-color-warning-light-3) 100%);
  border: none;
  color: white;
  transition: all 0.3s ease;
}

.expand-hint:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.expand-icon {
  font-size: 14px;
  transition: transform 0.3s ease;
}

:deep(.el-collapse-item.is-active) .expand-icon {
  transform: rotate(180deg);
}

.attachment-badge :deep(.el-badge__content) {
  background-color: var(--el-color-danger);
}

/* 当有附件时添加特殊效果 */
.attachment-collapse:has(.el-badge:not([hidden])) {
  position: relative;
}

.attachment-collapse:has(.el-badge:not([hidden])):before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, 
    var(--el-color-warning) 0%, 
    var(--el-color-danger) 50%, 
    var(--el-color-warning) 100%
  );
  border-radius: 3px 3px 0 0;
  animation: shine 2s infinite;
}

@keyframes shine {
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
}


/* 添加分数前缀的样式 */
.score-prefix {
  color: #606266;
  font-size: 14px;
  margin-right: 4px;
}

/* 修改删除按钮的样式 */
.option-row .el-button {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.option-row .el-button .el-icon {
  margin-right: 0;
  font-size: 16px;
}

/* 添加根节点图标颜色 */
.root-icon {
  color: #FF9800; /* 橙色 */
}

.project-selection {
  width: 100%;
}

.dialog-footer {
  padding: 20px 0 0;
  text-align: right;
}
</style>