import ENV from '../../.env.js';
import axios from 'axios';
import { showNotification } from './index';  // 确保正确导入
import router from '../router';  // 导入路由

// 使用环境变量中的BASE_URL
const BASE_URL = ENV.BASE_URL;

// 请求拦截器
const requestInterceptor = (config) => {
  // 从缓存中获取token
  const userInfoString = localStorage.getItem('userInfo');
  // 将JSON字符串转换为JSON对象
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  // 如果有token，则添加到header中
  if (userInfo && userInfo.token) {
    config.headers['Authorization'] = userInfo.token;
  }
  return config;
};

// 响应拦截器
const responseInterceptor = (response) => {
  const { status, data } = response;

  if (status >= 200 && status < 300) {
    return data;
  } else {
    const error = new Error(data.message || '请求失败');
    error.response = response;
    throw error;
  }
};

// 错误处理函数
const handleError = (error) => {
  console.error('请求错误:', error);
  let message = '网络错误，请稍后重试';

  if (error.response) {
    const { status, data } = error.response;
    switch (status) {
      case 400:
        message = data.msg || '请求参数错误';
        break;
      case 401:
        message = '未授权，请重新登录';
        // 清除本地存储的用户信息和token
        localStorage.removeItem('userInfo');
        localStorage.removeItem('token');
        // 跳转到登录页面
        router.push('/login');
        break;
      case 403:
        message = '拒绝访问';
        break;
      case 404:
        message = '请求的资源不存在';
        break;
      case 500:
        message = data.msg || '服务器错误';
        break;
      default:
        message = `未知错误(${status})`;
    }
  } else if (error.request) {
    message = '服务器无响应，请检查网络连接';
  } else {
    message = error.message;
  }

  showNotification(message, 'error');
  return Promise.reject(error);
};

// 主请求函数
const request = (options) => {
  const { url, method = 'GET', data, headers = {} } = options;

  // 设置通用请求头
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };

  // 合并默认请求头和自定义请求头
  const mergedHeaders = {
    ...defaultHeaders,
    ...headers
  };

  const config = requestInterceptor({ headers: mergedHeaders });

  return axios({
    url: `${BASE_URL}${url}`,
    method,
    data,
    headers: config.headers,
  }).then(response => {
    try {
      const result = responseInterceptor(response);
      return result;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }).catch(error => {
    console.error('Error in request:', error);
    return handleError(error);
  });
};

// 导出各种请求方法
export const get = (url, params = {}, headers = {}) => {
  // 将params对象转换为查询字符串
  const queryString = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  // 如果有查询字符串，则将其附加到URL
  const fullUrl = queryString ? `${url}?${queryString}` : url;

  return request({ url: fullUrl, method: 'GET', headers });
};

export const post = (url, data = {}, headers = {}) => {
  return request({ url, method: 'POST', data, headers });
};

export const put = (url, data = {}, headers = {}) => {
  return request({ url, method: 'PUT', data, headers });
};

export const del = (url, data = {}, headers = {}) => {
  return request({ url, method: 'DELETE', data, headers });
};

// 添加文件下载请求方法
export const downloadFile = async (url, data = {}, headers = {}) => {
  try {
    const defaultHeaders = {
      'Content-Type': 'application/json',
    };

    const mergedHeaders = {
      ...defaultHeaders,
      ...headers
    };

    const config = requestInterceptor({ headers: mergedHeaders });

    const response = await axios({
      url: `${BASE_URL}${url}`,
      method: 'POST',
      data,
      headers: config.headers,
      responseType: 'blob',
    });

    const contentType = response.headers['content-type'];
    let filename = data.file_name || 'download';
    const fileExtension = getFileExtension(data.file_type, contentType);
    if (!filename.endsWith(fileExtension)) {
      filename += fileExtension;
    }

    // 在 switch 外部声明变量
    let blob;
    let text;
    let csvContent;
    
    // 根据不同文件类型处理
    switch (data.file_type) {
      case 'csv': {
        // CSV 文件处理
        text = await new Response(response.data).text();
        csvContent = '\uFEFF' + text; // 添加 BOM
        blob = new Blob([csvContent], { 
          type: 'text/csv;charset=utf-8' 
        });
        break;
      }
      
      case 'pdf': {
        // PDF 文件处理
        blob = new Blob([response.data], { 
          type: 'application/pdf' 
        });
        break;
      }
        
      default:
        blob = new Blob([response.data], { type: contentType });
    }

    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    
    window.URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(link);

    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};

// 根据文件类型和Content-Type获取文件扩展名
function getFileExtension(fileType, contentType) {
  if (!fileType) {
    // 根据 Content-Type 判断
    switch (contentType) {
      case 'text/csv':
        return '.csv';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '.xlsx';
      case 'application/pdf':
        return '.pdf';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '.docx';
      default:
        return '.txt';
    }
  }

  // 根据请求的文件类型判断
  switch (fileType.toLowerCase()) {
    case 'csv':
      return '.csv';
    case 'excel':
      return '.xlsx';
    case 'pdf':
      return '.pdf';
    case 'word':
      return '.docx';
    default:
      return '.txt';
  }
}

export default request;
